import styled from "@emotion/styled";
import React from "react";

interface RectArrowButtonProps {
    label: string;
}

export default function RectArrowButton(props: RectArrowButtonProps) {
    return (
        <Container className="rect_arrow_button">{props.label}</Container>
    );
}

const Container = styled.span`

  display: inline-block;
  position: relative;
  background: none;
  border: 1px solid #fff;
  padding: 0.1rem 6rem 0.1rem 1.8rem;
  transition: all .3s;

  //font-family: YuMincho;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0.1em;
  line-height: 38px;
  text-align: left;
  color: #fff;

  &:after {
    content: "";
    position: absolute;
    display: block;
    transition: all .3s;
    width: 7px;
    height: 40px;
    top: 50%;
    right: 3rem;
    border-top: solid 2px #fff;
    border-right: solid 2px #fff;
    transform: translateY(-50%) rotate(45deg) skewX(-45deg);;

  }

  &:hover {
    background-color: #fff;
    color: #000;

    &:before {
      background: #fff;
    }

    &:after {
      border-color: #614f38;
    }
  }

`;
