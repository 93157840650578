import styled from "@emotion/styled";
import {Link} from "gatsby";
import React from "react";
import {StaticImage} from "gatsby-plugin-image";
import RectArrowButton from "../../common/RectArrowButton";

export default function ProductCollection() {
    return (
        <Container>

            <Link to="/workwear" className="bl_collection">
                <div className="bl_collection_image">
                    <StaticImage
                        src="../../../assets/img/product/workwear.jpg"
                        alt="work wear"
                        placeholder="blurred"
                        objectPosition="right"
                        objectFit="contain"
                        className="image_pic"
                        quality={100}
                    />
                </div>
                <div className="bl_collection_desc">
                    <div className="bl_collection_desc_wrap">
                        <div className="bl_collection_cap">
                            <span className="series_id">01</span>
                            <div>
                                <h4 className="title_jp">ワークウェア</h4>
                                <span className="title_en">WORK WEAR</span>
                            </div>
                            <p>
                                毎日着てもラフにならず、清潔感と美しさを保つ仕事
                            </p>
                        </div>
                        <div className="bl_collection_button">
                            <RectArrowButton label="もっと詳しく"/>
                        </div>
                    </div>
                </div>
            </Link>

            <Link to="/texturelab" className="bl_collection layout_right">
                <div className="bl_collection_image">
                    <StaticImage

                        src="../../../assets/img/product/texturelab.jpg"
                        alt="work wear"
                        placeholder="blurred"
                        objectPosition="left"
                        objectFit="contain"
                        className="image_pic"
                        quality={100}
                    />
                </div>
                <div className="bl_collection_desc">
                    <div className="bl_collection_desc_wrap">
                        <div className="bl_collection_cap">
                            <span className="series_id">02</span>
                            <h4 className="title_jp">テクスチャー ラボ</h4>
                            <span className="title_en">Texture LAB</span>
                            <p>
                                極上の触感と柔らかさ<br/>
                                小ロット生産の究極の1着
                            </p>
                        </div>
                        <div className="bl_collection_button">
                            <RectArrowButton label="もっと詳しく"/>
                        </div>
                    </div>
                </div>
            </Link>
        </Container>
    );
}



const Container = styled.div`

  width: 100%;

  .image_pic {
    height: 100%;
    width: 100%;
  }

  .bl_collection {
    width: 100%;
    height: 80vh;
    min-height: 600px;
    max-height: 900px;
    margin-top: 120px;
    display: flex;

    @media (max-width: 768px) {
      height: auto;
      max-height: none;
      flex-direction: column;
    }

    .bl_collection_image {
      height: 100%;
      flex: 1 1 47%;
    }

    &_desc {
      height: 100%;
      flex: 1 1 53%;
      position: relative;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      &_wrap {
        text-align: left;
        margin-left: 10%;
        
        @media (max-width: 768px) {
          margin: 0 20px;
        }

      }

    }

    @media (min-width: 769px) {
      &.layout_right {

        .bl_collection_image {
          order: 2;
        }

        .bl_collection_desc {
          order: 1;
          align-items: flex-end;
          padding-right: 30px;
        }
      }
    }


    &_cap {

      h4.title_jp {
        margin-left: -6px;
        margin-top: 120px;
        white-space: nowrap;
        max-width: 100%;

        //font-family: YuMincho;
        font-weight: 500;
        font-size: 46px;
        letter-spacing: 0.05em;
        text-align: left;
        color: #cbcbcb;

        @media (max-width: 768px) {

          font-size: 34px;
          font-size: min(10vw, 34px);

          height: auto;
          max-height: none;
          max-width: 100%;
          flex-direction: column;
          margin-top: 60px;
        }
      }

      span.series_id {
        margin-left: -6px;

        //font-family: Didot;
        font-weight: normal;
        font-size: 136px;
        letter-spacing: 0.05em;
        line-height: 17px;
        text-align: left;
        color: #cbcbcb;
        opacity: 0.31;
      }

      span.title_en {
        margin-top: 24px;

        //font-family: Didot;
        font-weight: normal;
        font-size: 24px;
        letter-spacing: 0.06em;
        line-height: 60px;
        text-align: left;
        color: #9b8750;
      }

      p {
        margin-top: 36px;
        margin-bottom: 60px;

        //font-family: YuMincho;
        font-weight: 500;
        font-size: 14px;
        font-size: min(4vw, 14px);
        letter-spacing: 0.08em;
        line-height: 27px;
        text-align: left;
        color: #fff;
    }

    &_button {
      margin: 20px auto;
    }
  }
  
  

  @media (max-width: 768px) {

    .bl_collection {
      margin-top: 60px;

      &_image {
        flex: 1 1 80%;
      }

      &_desc {
        flex: 1 1 20%;

        &_wrap {
          left: -200%;
        }
      }

      &_cap {
        h4 {
          font-size: 39px;
          line-height: 44px;
        }

        h4.homecouture {
          font-size: 49px;
          line-height: 39px;
          text-align: left;
        }
      }

      &_button {
        margin: 16px auto;
      }
    }
  }

  
`;

