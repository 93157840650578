import styled from "@emotion/styled";
import React from "react";
import {StaticImage} from "gatsby-plugin-image";

export default function ProductCoverView() {
    return (
        <Container>
            <StaticImage
                src={"../../../assets/img/product/title.svg"}
                alt="創造の思考"
                placeholder="tracedSVG"
            />
        </Container>
    );
}

const Container = styled.div`

  min-height: max(580px, 100vh);
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1ch;
`;
