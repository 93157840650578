import React from "react";
import styled from "@emotion/styled/";
import icon from "../../assets/common/store.svg"

export default function OnlineStoreRectButton() {
    return (
        <Container>
            <a className="online-button" href="https://shop.knitology.net/">オンラインストア</a>
        </Container>
    );
}
const Container = styled.div`
  
  max-width: 280px;
  margin: 0 auto;
  
  .online-button {
    display: block;
  }

  a.online-button {
    padding: 1em 2em;
    margin: 10px 0;
    display: block;

    //font-family: YuMincho;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: -0.04em;
    line-height: 38px;
    color: #fff;
    background: #2F2F2F;
    border-radius: 20px;
    text-align: center;
  }

  a.online-button:before {
    content: "";
    display: inline-block;
    width: 18px;
    height: 18px;
    margin: -3px 18px 0 0;

    background: url(${icon}) no-repeat;
    background-size: contain;
    vertical-align: middle;
  }

  a.online-button:hover {
    background: #9B8750;
  }

`;
