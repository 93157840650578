import styled from "@emotion/styled";
import {StaticImage} from "gatsby-plugin-image";
import React from "react";

export default function SouzouImage() {
    return (
        <Container>
            <div className="bl-manifest-image">
                <StaticImage
                    className="hideSP"
                    src="../../../assets/img/product/manifesto.png"
                    alt="image"
                    quality={100}
                />
                <StaticImage
                    className="hidePC"
                    src="../../../assets/img/product/manifesto_sp.png"
                    alt="image"
                    layout="constrained"
                    width={768}
                    quality={100}
                />
            </div>
        </Container>
    );
}

const Container = styled.div`
  
  width: 100%;
  max-width: 900px;
  padding: 0 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 0 20px;
  }

  .bl-title-image {
    width: 100%;
    margin-top: 78px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .bl-manifest-image {
    margin: 125px auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .bl-drawing {
    margin-top: 57px;
    width: 100%;
  }
`;