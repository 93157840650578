import styled from "@emotion/styled";
import React from "react";
import Layout from "../components/layout";
import ProductCollection from "../components/pages/product/ProductCollection";
import SouzouImage from "../components/pages/product/SouzouImage";
import BoarderSectionTitle from "../components/common/BoarderSectionTitle";
import ShopInfoList from "../components/pages/about/ShopInfoList";
import ItemListBlock from "../components/pages/ItemListBlock";
import {StaticImage} from "gatsby-plugin-image";
import CoverView2 from "../components/common/CoverView2";
import ProductCoverView from "../components/pages/product/ProductCover";

export default function Product() {
    return (
        <Layout>
            <ProductCoverView/>
            {/*<CoverView2 sp_slide="65%" image={*/}
            {/*    <StaticImage*/}
            {/*        src="../assets/img/product/product_cover.webp"*/}
            {/*        alt="top"*/}
            {/*        objectFit="cover"*/}
            {/*        layout="fullWidth"*/}
            {/*        quality={100}*/}
            {/*        className="top-cover"*/}
            {/*    />*/}
            {/*}>*/}
            {/*    <StaticImage*/}
            {/*        src={"../assets/img/product/product_title.png"}*/}
            {/*        alt="どうやったらよいものが作れるか"*/}
            {/*        placeholder="tracedSVG"*/}
            {/*    />*/}
            {/*</CoverView2>*/}
            <ProductIntro>
                <SouzouImage></SouzouImage>
                <div className="bl-collection">
                    <BoarderSectionTitle title="SERIES"></BoarderSectionTitle>
                    <ProductCollection></ProductCollection>
                </div>
                <div className="bl-online">
                    <BoarderSectionTitle title="ONLINE STORE"></BoarderSectionTitle>
                    <ItemListBlock/>
                </div>
                <div className="bl-stockist">
                    <BoarderSectionTitle title="STORE"></BoarderSectionTitle>
                    <div className="bl-stockist-wrap">
                        <ShopInfoList></ShopInfoList>
                    </div>
                </div>
            </ProductIntro>
        </Layout>
    );
}

const ProductIntro = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: #000;
  margin-top: 100px;

  .bl-collection {
    margin-top: 160px;
    padding: 100px 0 160px;
  }

  .bl-stockist-wrap {
    margin: 0 auto 160px;
    max-width: 990px;
  }

`;
