import React from "react";
import styled from "@emotion/styled";
import OnlineStoreRectButton from "../common/OnlineStoreRectButton";

export default function ItemListBlock() {
    return (
        <Container>
            <div className="bl-online-wrap">
                <span className="bl-online-desc">商品一覧はこちら</span>
                <span className="arrow sample1-1 hideSP"/>
                <span className="dli-arrow-down hidePC"/>
                <div>
                    <OnlineStoreRectButton/>
                </div>
            </div>
        </Container>
    );
}

const Container = styled.div`

  .bl-online-wrap {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-top: 140px;
    margin-bottom: 100px;

    @media (max-width: 768px) {
      flex-direction: column;
      margin-left: 30px;
      margin-right: 30px;
      text-align: center;
      gap: 14px;
    }
  }

  .bl-online-desc {
    display: block;
    color: #666;
    display: grid;
    place-content: center;
    gap: 1ch;
    text-align: right;
    //flex: 1 1 200px;
  }

  .arrow {
    position: relative;
    display: inline-block;
    padding: 0 0 0 36px;
    color: #000;
    vertical-align: middle;
    text-decoration: none;
    font-size: 15px;

    @media (max-width: 768px) {
      display: block;
      margin: 0 auto;
    }
  }

  .arrow::before,
  .arrow::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    content: "";
    vertical-align: middle;

    @media (max-width: 768px) {
      left: 50%;
    }
  }

  .sample1-1::before {
    left: 4px;
    width: 25px;
    height: 1px;
    background: #666;
  }

  .sample1-1::after {
    left: 18px;
    width: 10px;
    height: 10px;
    border-top: 1px solid #666;
    border-right: 1px solid #666;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }


  .dli-arrow-down {
    display: block;
    vertical-align: middle;
    color: #2F2F2F;
    line-height: 1;
    position: relative;
    width: 1px;
    height: 15px;
    background: #666;
    margin: 0 auto;
  }

  .dli-arrow-down::before {
    content: '';
    width: 9px;
    height: 9px;
    border: 1px solid #666;
    border-left: 0;
    border-bottom: 0;
    box-sizing: border-box;
    transform: rotate(135deg);
    position: absolute;
    left: -4px;
    bottom: 0;
    margin: auto;
  }


`